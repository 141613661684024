import axios, { CancelTokenSource } from 'axios';
import { gzip, inflate } from 'pako';

import message from 'antd/es/message';
import { captureException } from '@sentry/react';
import { BASE_URL, BLUEPRINT_BASE_URL } from '../Constants/Urls';
import { IFRAME_ERROR_TYPE, KNOWN_EXCEPTIONS, USAGE_MODE } from '../Constants/Constant';
import { storage } from '../Stores/AppStore';
import { postMessageToReferrer } from './HelperFunctions';
import { SESSION_EXPIRED } from '../Constants/Messages';
import CustomWindowProps from '../types/customWindow';
import { determineUserMode } from './usersTypes';

const cancelTokenInstance = axios.CancelToken;
export const getAxiosTokenSource = (): CancelTokenSource => cancelTokenInstance.source();
export const isCancelledRequest = axios.isCancel;

/**
 * @name makeURL
 * @function
 * @description Used to create URL with Base Url
 * @param {String} URL API end point URL
 */
export function makeURL(URL: $TSFixMe) {
  const mode = determineUserMode();
  if (mode === USAGE_MODE.BLUEPRINT) {
    return BLUEPRINT_BASE_URL + makeRequestBluePrintAPI(URL);
  }
  return BASE_URL + URL;
}
export function getTokenType(): string {
  const customWindow: CustomWindowProps = window;

  if (storage.get('accelerateToken') || customWindow.isEmbed) {
    return 'Bearer ';
  }
  return 'Token ';
}

export function makeRequestBluePrintAPI(url: string): string {
  if (url?.startsWith('requests/')) {
    return url.replace('requests/', 'user-requests/');
  }
  return url;
}

/**
 * @name getAuthToken
 * @function
 * @description Returns token from storage
 */
export function getAuthToken() {
  let returnValue = null;
  // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
  if (window.isEmbed) {
    returnValue = storage.get('api_key');
  } else if (storage.get('accelerateToken')) {
    returnValue = storage.get('accelerateToken');
  } else if (storage.get('blueprintToken')) {
    returnValue = storage.get('blueprintToken');
  } else if (storage.get('token')) {
    returnValue = storage.get('token');
  }
  return returnValue;
}

/**
 * @name multipartAPI
 * @function
 * @description Used to call API with multipart data
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export function multipartAPI(
  URL: $TSFixMe,
  { data, prefix = '', params = {}, method = 'POST', onUploadProgress = () => {}, cancelToken }: $TSFixMe = {}
) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url: makeURL(prefix + URL),
      params,
      data,
      headers: {
        Authorization: getTokenType() + getAuthToken(),
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress,
      cancelToken
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
        handleErrorCode(err);
      });
  });
}

/**
 * @name postAPI
 * @function
 * @description Used to call API with POST method
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export function postAPI(
  URL: $TSFixMe,
  { data = {}, prefix = '', cancelToken = null, isGzip = false, params = {}, onUploadProgress = () => {} } = {}
) {
  return new Promise((resolve, reject) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    axios({
      method: 'POST',
      url: makeURL(prefix + URL),
      params,
      data: isGzip ? gzip(JSON.stringify(data)) : data,
      cancelToken,
      onUploadProgress,
      headers: {
        Accept: 'application/json; version=1.0',
        Authorization: getTokenType() + getAuthToken(),
        'Content-Encoding': 'gzip'
      }
    })
      .then(res => {
        let { data: _data } = res;
        if (res.headers['content-type'] === 'application/gzip') {
          _data = gzipToJson(_data);
        }
        resolve(_data);
      })
      .catch(err => {
        reject(err);
        handleErrorCode(err);
      });
  });
}
/**
 * @name patchAPI
 * @function
 * @description Used to call API with patch(default) method
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export function patchAPI(URL: $TSFixMe, { data = {}, prefix = '', params = {}, method = 'PATCH' } = {}) {
  return new Promise((resolve, reject) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    axios({
      method,
      url: makeURL(prefix + URL),
      params,
      data,
      headers: {
        Authorization: getTokenType() + getAuthToken(),
        'Content-Encoding': 'gzip'
      }
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
        handleErrorCode(err);
      });
  });
}
/**
 * @name getAPI
 * @function
 * @description Used to call API with GET method
 * @param {String} URL API URL
 * @param {Object} params Source data
 * @param {Object} options options
 */

export function getAPI<T>(URL: $TSFixMe, { params = {}, prefix = '', options = {}, c_url = false } = {}) {
  return new Promise<T>((resolve, reject) => {
    axios({
      method: 'GET',
      url: c_url ? URL : makeURL(prefix + URL),
      params,
      // @ts-expect-error TS(2339): Property 'cancelToken' does not exist on type '{}'... Remove this comment to see the full error message
      cancelToken: options?.cancelToken,
      headers: {
        Accept: 'application/json; version=1.0',
        Authorization: getTokenType() + getAuthToken()
      }
    })
      .then(res => {
        let { data } = res;
        if (res.headers['content-type'] === 'application/gzip') {
          data = gzipToJson(data);
        }

        resolve(data as T);
      })
      .catch(err => {
        reject(err);

        if (
          // @ts-expect-error TS(2339): Property 'skipErrorStatus' does not exist on type ... Remove this comment to see the full error message
          err?.response?.status !== options?.skipErrorStatus &&
          // @ts-expect-error TS(2339): Property 'skipErrorStatusList' does not exist on t... Remove this comment to see the full error message
          !options?.skipErrorStatusList?.includes(err?.response?.status)
        ) {
          handleErrorCode(err);
        }
      });
  });
}

/**
 * @name openAPI
 * @function
 * @description Used to login (Calls API without token)
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
// eslint-disable-next-line func-names
export const openAPI = function (
  URL: $TSFixMe,
  data: $TSFixMe,
  method = 'POST',
  options = {},
  params = {},
  prefix = ''
) {
  return new Promise((resolve, reject) => {
    const headers = {
      Accept: 'application/json; version=1.0'
    };
    // @ts-expect-error TS(2769): No overload matches this call.
    axios({
      method,
      url: makeURL(prefix + URL),
      data,
      params,
      headers
    })
      .then(res => {
        let { data: _data } = res;
        if (res.headers['content-type'] === 'application/gzip') {
          _data = gzipToJson(_data);
        }
        resolve(_data);
      })
      .catch(err => {
        reject(err);

        // @ts-expect-error TS(2339): Property 'skipErrorStatus' does not exist on type ... Remove this comment to see the full error message
        if (err?.response?.status !== options?.skipErrorStatus) {
          handleErrorCode(err);
        }
      });
  });
};

/**
 * @name thirdPartyAPI
 * @function
 * @description Used to call any third party API
 * @param {String} URL API URL
 */
export async function thirdPartyAPI(URL: $TSFixMe, data: $TSFixMe, method = 'GET') {
  // @ts-expect-error TS(2769): No overload matches this call.
  const response = await axios({
    method,
    url: URL,
    data
  });
  return response.data;
}

/**
 * @name interpolate
 * @function
 * @description Replace %s from a string to given number
 * @param {String} theString String with %s
 * @param {Array} argumentArray Array of numbers
 */
export function interpolate(theString: $TSFixMe, argumentArray: $TSFixMe) {
  const regex = /%s/;
  const regexFunc = function regexFunc(p: $TSFixMe, c: $TSFixMe) {
    return p.replace(regex, c);
  };
  return argumentArray.reduce(regexFunc, theString);
}

/**
 * @name gzipToJson
 * @function
 * @description converts gzip data to object
 * @param {gzip} data value
 */
export const gzipToJson = function gzipToJson(data: $TSFixMe) {
  return JSON.parse(inflate(Buffer.from(data, 'base64'), { to: 'string' }));
};

export const handleErrorCode = function handleErrorCode(err: $TSFixMe) {
  if (err?.response?.data?.errors?.length) {
    if (err.response.data.error_type === 'AuthenticationFailed') {
      // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
      if (!window.isEmbed) {
        storage.remove('token');
        storage.remove('accelerateToken');
        window.location.href = '/';
      }
    }
    // Hide global error promt from known exceptions
    const knownErr = Object.values(KNOWN_EXCEPTIONS);
    if (knownErr.indexOf(err.response.data.error_type) === -1) {
      const errorDict = err.response.data.errors[0];
      let errMessage =
        errorDict.message || (errorDict.errors && errorDict.errors.length && errorDict.errors[0].message) || null;

      // If token expired display only one time
      if (errMessage === 'Invalid token.') {
        if (storage.get('invalid_token_message_displayed') !== '1') {
          storage.set('invalid_token_message_displayed', '1');
        } else return;
        // @ts-expect-error TS(2339): Property 'isEmbed' does not exist on type 'Window ... Remove this comment to see the full error message
        if (window.isEmbed) {
          document.dispatchEvent(new CustomEvent('token_expired', {}));
          postMessageToReferrer({ error_type: IFRAME_ERROR_TYPE.TOKEN_EXPIRED });
        }
        errMessage = SESSION_EXPIRED;
      }

      // Handling nested errors
      if (errMessage) message.error(errMessage);
    }
  }
};

/**
 * @name deleteAPI
 * @function
 * @description Used to call API with DELETE method
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export const deleteAPI = (URL: string, data = {}, params = {}) => {
  return multipartAPI(URL, { method: 'DELETE', data, params });
};

/**
 * Retries a given asynchronous function a specified number of times if it fails.
 *
 * The function will execute the provided `fn` function and, if it fails, will retry it up to
 * the specified number of retries. If all retries fail, the error will be propagated.
 *
 * @param {() => Promise<T>} fn - The asynchronous function to be executed and retried on failure.
 * @param {number} [retries=3] - The number of retry attempts before propagating the error. Defaults to 3.
 * @returns {Promise<T>} - A promise that resolves with the result of the successful execution of `fn`.
 * @throws {Error} - Throws the error if all retry attempts fail.
 *
 * @template T - The type of the result returned by the `fn` function.
 */
export const retryApiCall = async <T>(fn: () => Promise<T>, retries: number = 3): Promise<T> => {
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      return await fn(); // Try to execute the function
    } catch (error) {
      if (attempt === retries - 1) {
        throw error; // If all retries fail, propagate the error
      }
      captureException(error); // capture retries exception
    }
  }

  throw new Error('All Retries attempt failed.');
};
