export const WEEK_START = 1; // Monday
export const TEXT_DETECTION_ENDPOINT = `${process.env.NEXT_PUBLIC_BASE_URL}/external/extract_text_from_image/`;
export const USER_OS = {
  WIN: 'Windows',
  MAC: 'Mac OS',
  LINUX: 'Linux',
  IOS: 'iOS',
  ANDROID: 'Android',
  UNKNOWN: 'Unknown'
};

export const BROWSER_REGEX = {
  CHROME: /chrome|crios|crmo/i,
  CHROME_WITH_VERSION: /(?:chrome|crios|crmo)\/(\d+\.\d+)/i,
  SAFARI: /safari/i,
  SAFARI_WITH_VERSION: /version\/(\d+\.\d+)/i,
  EDGE: /edg/i,
  EDGE_WITH_VERSION: /edg\/(\d+\.\d+)/i,
  FIREFOX: /firefox|fxios/i,
  FIREFOX_WITH_VERSION: /(?:firefox|fxios)\/(\d+\.\d+)/i,
  INTERNET_EXPLORER: /msie|trident/i,
  OPERA: /opera|opr/i,
  OPERA_WITH_VERSION: /(?:opera|opr)\/(\d+\.\d+)/i
};
