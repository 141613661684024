import React from 'react';

const ReclassifyMergeIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_5870_55640)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M7.3166 10C7.75252 10.3328 8.28575 10.513 8.83417 10.513H12.1675C13.1492 10.5131 14.0993 10.8597 14.8505 11.4918C15.6016 12.1238 16.1055 13.0008 16.2733 13.968C16.8389 14.1525 17.3202 14.5326 17.6308 15.0401C17.9413 15.5475 18.0608 16.1491 17.9677 16.7367C17.8747 17.3243 17.5752 17.8594 17.1231 18.2461C16.6709 18.6328 16.0958 18.8456 15.5008 18.8463C14.9188 18.8468 14.3548 18.6441 13.9062 18.2733C13.4575 17.9025 13.1523 17.3867 13.0432 16.815C12.9341 16.2433 13.0279 15.6514 13.3085 15.1414C13.589 14.6314 14.0387 14.2353 14.58 14.0213C14.4355 13.4923 14.121 13.0255 13.6851 12.6927C13.2492 12.3599 12.7159 12.1797 12.1675 12.1797H8.83417C7.93241 12.181 7.05478 11.8885 6.33417 11.3463V13.988C6.89042 14.1846 7.35925 14.5715 7.6578 15.0803C7.95636 15.5892 8.06541 16.1872 7.96568 16.7687C7.86595 17.3501 7.56386 17.8776 7.11282 18.2579C6.66177 18.6382 6.0908 18.8468 5.50084 18.8468C4.91088 18.8468 4.33991 18.6382 3.88886 18.2579C3.43781 17.8776 3.13573 17.3501 3.036 16.7687C2.93627 16.1872 3.04532 15.5892 3.34387 15.0803C3.64243 14.5715 4.11126 14.1846 4.66751 13.988V12.8463V11.3463V10H7.3166ZM6.09009 16.9356C5.93381 17.0919 5.72185 17.1797 5.50084 17.1797C5.27982 17.1797 5.06786 17.0919 4.91158 16.9356C4.7553 16.7793 4.66751 16.5674 4.66751 16.3463C4.66751 16.1253 4.7553 15.9134 4.91158 15.7571C5.06786 15.6008 5.27982 15.513 5.50084 15.513C5.72185 15.513 5.93381 15.6008 6.09009 15.7571C6.24637 15.9134 6.33417 16.1253 6.33417 16.3463C6.33417 16.5674 6.24637 16.7793 6.09009 16.9356ZM16.0901 16.9356C15.9338 17.0919 15.7219 17.1797 15.5008 17.1797C15.2798 17.1797 15.0679 17.0919 14.9116 16.9356C14.7553 16.7793 14.6675 16.5674 14.6675 16.3463C14.6675 16.1253 14.7553 15.9134 14.9116 15.7571C15.0679 15.6008 15.2798 15.513 15.5008 15.513C15.7219 15.513 15.9338 15.6008 16.0901 15.7571C16.2464 15.9134 16.3342 16.1253 16.3342 16.3463C16.3342 16.5674 16.2464 16.7793 16.0901 16.9356Z'
          fill='#666666'
        />
        <path
          d='M5.625 2.08334C6.0901 2.08334 6.55064 2.17494 6.98034 2.35293C7.41003 2.53091 7.80046 2.79179 8.12934 3.12067C8.45821 3.44954 8.71909 3.83997 8.89707 4.26967C9.07506 4.69936 9.16667 5.1599 9.16667 5.625V9.16667H5.625C4.68569 9.16667 3.78485 8.79353 3.12066 8.12934C2.45647 7.46515 2.08333 6.56431 2.08333 5.625C2.08333 4.68569 2.45647 3.78486 3.12066 3.12067C3.78485 2.45647 4.68569 2.08334 5.625 2.08334ZM7.5 7.5V5.625C7.5 5.25416 7.39003 4.89165 7.18401 4.58331C6.97798 4.27497 6.68514 4.03464 6.34253 3.89273C5.99992 3.75081 5.62292 3.71368 5.25921 3.78603C4.89549 3.85838 4.5614 4.03695 4.29917 4.29918C4.03695 4.5614 3.85837 4.89549 3.78603 5.25921C3.71368 5.62292 3.75081 5.99992 3.89273 6.34253C4.03464 6.68515 4.27496 6.97798 4.58331 7.18401C4.89165 7.39004 5.25416 7.5 5.625 7.5H7.5ZM14.375 2.08334C15.3143 2.08334 16.2151 2.45647 16.8793 3.12067C17.5435 3.78486 17.9167 4.68569 17.9167 5.625C17.9167 6.56431 17.5435 7.46515 16.8793 8.12934C16.2151 8.79353 15.3143 9.16667 14.375 9.16667H10.8333V5.625C10.8333 4.68569 11.2065 3.78486 11.8707 3.12067C12.5349 2.45647 13.4357 2.08334 14.375 2.08334ZM14.375 7.5C14.7458 7.5 15.1084 7.39004 15.4167 7.18401C15.725 6.97798 15.9654 6.68515 16.1073 6.34253C16.2492 5.99992 16.2863 5.62292 16.214 5.25921C16.1416 4.89549 15.963 4.5614 15.7008 4.29918C15.4386 4.03695 15.1045 3.85838 14.7408 3.78603C14.3771 3.71368 14.0001 3.75081 13.6575 3.89273C13.3149 4.03464 13.022 4.27497 12.816 4.58331C12.61 4.89165 12.5 5.25416 12.5 5.625V7.5H14.375Z'
          fill='#666666'
        />
      </g>
      <defs>
        <clipPath id='clip0_5870_55640'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReclassifyMergeIcon;
