import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import chunkReloadHandler from './Utils/ChunkReloadHandler';

import 'antd/dist/antd.less';
import './Styles/global/style.less';
import './Styles/global/tailwind.less';
import './Styles/global/ant.less';
import './Styles/mobile.less';
import './Styles/features.less';

import App from './App';
import { storage } from './Stores/AppStore';
import ErrorBoundary from './Components/ErrorBoundary';
import PosthogProvider from './Providers/PosthogProvider';

// Error event listener
window.onerror = function onerror(message) {
  // Handle chunk load error
  // @ts-expect-error TS(2339): Property 'includes' does not exist on type 'string... Remove this comment to see the full error message
  if (message.includes('ChunkLoadError')) {
    chunkReloadHandler();
  }
};

const isProduction = process.env.APP_ENV === 'prod';
const isLocalhost = window.location.hostname.includes('localhost');

if (['prod', 'stage'].includes(process.env.APP_ENV as string) && process.env.POSTHOG_PROJECT_KEY) {
  posthog.init(process.env.POSTHOG_PROJECT_KEY, {
    api_host: process.env.POSTHOG_API_HOST
  });
}

// Initialize sentry
if (!isLocalhost && ['prod', 'stage', 'qa'].includes(process.env.APP_ENV as string)) {
  Sentry.init({
    dsn: process.env.SENTRY_KEY,
    integrations: [new Integrations.BrowserTracing()],
    environment: isProduction ? 'production' : process.env.APP_ENV,
    tracesSampleRate: 1,
    release: process.env.SENTRY_RELEASE,
    ignoreErrors: ['TypeError: Failed to fetch']
  });
  const email = storage.get('emailId');
  if (email) {
    Sentry.configureScope(scope => {
      // Set user if email is available
      scope.setUser({ user_email: email });
    });
  }
}

const logBrowserIncompatibleEvent = (message: string) => {
  Sentry.captureMessage(message, {
    user: {
      email: storage.get('emailId') || '-' // Set user email if available
    },
    level: Sentry.Severity.Warning
  });
};

const rootElement = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <PosthogProvider client={posthog}>
    <Router>
      <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
        <App />
      </Sentry.ErrorBoundary>
    </Router>
  </PosthogProvider>
);

// @ts-expect-error TS(1343): The 'import.meta' meta-property is only allowed wh... Remove this comment to see the full error message
if (import.meta.hot) {
  // @ts-expect-error TS(1343): The 'import.meta' meta-property is only allowed wh... Remove this comment to see the full error message
  import.meta.hot.accept();
}
