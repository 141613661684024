import { v4 as uuidv4 } from 'uuid';

/**
 * capitalize - capitalizes the input string eg: frontend -> Frontend
 *
 * @param {string} text
 * @returns capitalized string
 * @example
 * capitalize('frontend') // Frontend
 * capitalize('FRONTEND') // Frontend
 * capitalize('fRoNtEnD') // Frontend
 * capitalize('') // ''
 */
export const capitalize = (text: string) => (text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : '');

/**
 * snakeToCamel - converts snake case string to camel case
 *
 * @param {string} text
 * @returns string converted to camel case
 * @example
 * snakeToCamel('snake_case') // snakeCase
 * snakeToCamel('Snake_case') // snakeCase
 * snakeToCamel('') // ''
 * snakeToCamel('a1_b3_c4') // a1B3C4
 */
export const snakeCaseToCamelCase = (str: string) =>
  str ? str.charAt(0).toLowerCase() + str.slice(1).replace(/(_\w)/g, g => g[1].toUpperCase()) : '';

/**
 * Converts a string from camel case to snake case.
 *
 * @param {string} str - The input string in camel case.
 * @returns The string converted to snake case.
 * @example
 * camelCaseToSnakeCase('camelCase') // camel_case
 * camelCaseToSnakeCase('TitleCase') // title_case
 * camelCaseToSnakeCase('') // ''
 * camelCaseToSnakeCase('a1B3C4') // a1_b3_c4
 */
export const camelCaseToSnakeCase = (str: string) =>
  str ? str.replace(/[A-Z]/g, (letter, index) => (!index ? letter.toLowerCase() : `_${letter.toLowerCase()}`)) : '';

/**
 * Removes all whitespaces from given string and returns output string without whitespaces.
 *
 * @param {string} str - The input string with whitespaces.
 * @returns String without whitespaces.
 * @example
 * removeSpaces('remove spaces') // removespaces
 * removeSpaces('remove    spaces ') // removespaces
 * removeSpaces(' remove    spaces ') // removespaces
 * removeSpaces(' remove\nspaces ') // removespaces
 * removeSpaces('') // ''
 * removeSpaces('  ') // ''
 */
export const removeSpaces = (str: string) => (str ? str.replace(/\s/g, '') : '');

/**
 * Converts a string from snake case to space separated.
 *
 * @param {string} str - The input string in snake case.
 * @returns The string converted to space separated.
 * @example
 * snakeCaseToSpaceSeparated('snake_case') // snake case
 * snakeCaseToSpaceSeparated('') // ''
 */
export const snakeCaseToSpaceSeparated = (str: string) => (str ? str.replace(/_/g, ' ') : '');

/**
 * Converts a string from space separated to snake case.
 *
 * @param {string} str - The input string (space separated).
 * @returns The string converted to snake case.
 * @example
 * spaceSeparatedToSnakeCase('string with  spaces') // string_with_spaces
 * spaceSeparatedToSnakeCase('') // ''
 */
export const spaceSeparatedToSnakeCase = (str: string) => (str ? str.trim().replace(/\s+/g, '_') : '');

/**
 * Converts a capitalized string to space separated.
 *
 * @param {string} str - The input string in capitalized form.
 * @returns The string converted to space separated.
 * @example
 * capitalizedToSpaceSeparated('CapitalizedString') // Capitalized String
 * capitalizedToSpaceSeparated('') // ''
 */
export const capitalizedToSpaceSeparated = (str: string) => (str ? str.replace(/([A-Z])/g, ' $1').trim() : '');

/**
 * Converts a string to PascalCase.
 *
 * @param {string} str - The input string to be converted.
 * @returns The string converted to PascalCase.
 * @example
 * convertToPascalCase('pascal case') // PascalCase
 * convertToPascalCase('') // ''
 */
export const convertToPascalCase = (str: string) => {
  // Remove any non-alphanumeric characters and split the string into an array
  const words = (str || '').replace(/[^a-zA-Z0-9]+/g, ' ').split(' ');

  // Capitalize the first letter of each word and join them
  const pascalCase = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');

  return pascalCase;
};

/**
 * Extracts float numbers from a given string.
 *
 * @param {string} text - The input string.
 * @returns An array of float numbers extracted from the string.
 * @example
 * extractFloatNumbers('The price is 10.5 and 20.3') // [10.5, 20.3]
 * extractFloatNumbers('') // 0
 */
export const extractFloatNumbers = (text: string) =>
  text.trim() === ''
    ? 0
    : text
        ?.replaceAll(',', '')
        ?.match(/[+-]?\d+(\.\d+)?/g)
        ?.map(v => parseFloat(v));

/**
 * Converts a number to a fixed decimal representation if necessary.
 *
 * @param {string | number} value - The input value to be converted.
 * @param {number} numDecimalPlaces - The number of decimal places to round to.
 * @returns The input value converted to a fixed decimal representation.
 * @example
 * toFixedIfNecessary(10.123, 2) // 10.12
 * toFixedIfNecessary(10.123, 3) // 10.123
 * toFixedIfNecessary('10.123', 2) // 10.12
 * toFixedIfNecessary('10.127', 2) // 10.13
 */
export const toFixedIfNecessary = (value: string | number, numDecimalPlaces: number) => {
  return +(typeof value === 'string' ? parseFloat(value) : value).toFixed(numDecimalPlaces);
};

/**
 * Serializes the given parameters into a query string.
 *
 * @param {Record<string, string | undefined | null>} params - The parameters to be serialized as an object.
 * @returns The serialized query string.
 * @example
 * serializeParams({ key1: 'value1', key2: 'value2' }) // key1=value1&key2=value2
 * serializeParams() // ''
 */
export const serializeParams = (params: Record<string, string | undefined | null> = {}) => {
  const keys = Object.keys(params);
  const str: string[] = [];
  keys.forEach(key => {
    const val = params[key];
    if (val) {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
    }
  });
  return str.join('&');
};

/**
 * Checks if the given string is a valid email address.
 *
 * @param {string} email - The email address to be validated.
 * @returns True if the email address is valid, false otherwise.
 * @example
 * isValidEmail('test1@attentive.ai') // true
 * isValidEmail('test1attentive.ai') // false
 * isValidEmail('') // false
 */
export const isValidEmail = (email: string) => {
  if (!email.length) return false;
  // As per the HTML Specification
  const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegExp.test(email);
};

/**
 * Compares two strings and returns a value indicating their relative order.
 *
 * @param {string} a - The first string to compare.
 * @param {string} b - The second string to compare.
 * @returns A negative number if `a` is less than `b`, a positive number if `a` is greater than `b`, or 0 if they are equal.
 * @example
 * stringCompare('frontend', 'Frontend') // -1
 * stringCompare('', 'frontend') // -1
 * stringCompare('frontend', '') // 1
 * stringCompare('frontend', 'frontend') // 0
 * stringCompare('Frontend', 'frontend') // 1
 */
export const stringCompare = (a: string, b: string) => {
  if (a == null || a === '') return -1;
  if (b == null || b === '') return 1;

  return a.localeCompare(b);
};

/**
 * Formats phone number string to XXX-XXX-XXXX format.
 *
 * @param {string} value - The string to be formatted as a phone number.
 * @param {string} previousValue - Optional. The previous value of the phone number for comparison.
 * @returns The formatted phone number string.
 * @example
 * formatPhoneNumber('1234567890') // '123-456-7890'
 * formatPhoneNumber('1234567') // '123-456-7'
 * formatPhoneNumber('123') // '123'
 * formatPhoneNumber('') // ''
 * formatPhoneNumber('1234567890', '9876543210') // '123-456-7890'
 */
export const formatPhoneNumber = (value: string, previousValue?: string): string => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
  return value;
};

/**
 * Generates a random string using the crypto.randomUUID() method if available,
 * otherwise falls back to generating a random hexadecimal string using Math.random().
 *
 * @returns A random string.
 * @example
 * getRandomString() // '067e6162-3b6d-4aec-a57a-466774f6876f'
 */
export const getRandomString = () => {
  return uuidv4();
};

export const camelCaseUnderScore = (str: string) => {
  const res = str.replace(/_([a-z])/g, g => ` ${g[1].toUpperCase()}`);
  return res.charAt(0).toUpperCase() + res.slice(1);
};

/**
 * It takes a string and a number as arguments and returns the string if it's length is less than or
 * equal to the number, otherwise it returns the string truncated to the number
 * @param {string} word - string - The word to be truncated
 * @param {number} requiredLength - The length of the string you want to truncate to.
 * @returns A function that takes two parameters and returns a string.
 */
export const truncateString = (word: string, requiredLength: number) => {
  return word?.length > requiredLength ? word?.slice(0, requiredLength) : word;
};
